<script setup lang="ts">
import { RouterView } from 'vue-router'
import { onMounted } from 'vue'
import { CONFIG } from '@/config';

onMounted(() => {
  document.title = `${CONFIG.appName} | Powerful, pay-as-you-go Minecraft servers`;
})

</script>

<template>
  <RouterView />
</template>
