export const amplifyConfig = {
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  },
  oauth: {
    redirectSignIn: `${window.location.origin}/dashboard/`,
    redirectSignOut: `${window.location.origin}/`,
    domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
    responseType: 'token',
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"]
  }
};