// stores/counter.js
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { Auth, Hub } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth'
import { useRouter } from 'vue-router'

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  identities: string;
  picture?: string;
}

interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes;
}

export const useAuthStore = defineStore('Auth', () => {
  console.log("DEFINING STORE")
  const router = useRouter();
  const currentUser: Ref<CognitoUserExt | undefined> = ref();
  const mfaMethod: Ref<string> = ref('');

  Hub.listen('auth', (data) => {
    console.debug(`Auth event (${data.payload.event}): `, data);

    switch (data.payload.event) {
      case 'signIn':
      case 'cognitoHostedUI':
        currentUser.value = data.payload.data;
        router.push({ name: 'list-servers' });
        break;
      case 'autoSignIn':
        currentUser.value = data.payload.data;
        router.push({ name: 'list-servers' });
        break;
      case 'autoSignInFailure':
        router.push({ name: 'login'});
        break;
      case 'signUp':
        router.push({ name: 'confirm-signup', query: { email: data.payload.data.user.username }})
        break;
      case 'signOut':
        router.push({ name: 'login' });
        currentUser.value = undefined;
        break;
      case 'forgotPassword':
        router.push({ name: 'set-new-password', query: { email: data.payload.data.username } });
        break;
      case 'forgotPasswordSubmit':
        router.push({ name: 'login' });
        break;
      default:
        console.warn(`no handler for auth event ${data.payload.event}`);
        break;
    }
  });

  async function signIn(email: string, password: string) {
    const user = await Auth.signIn(
      email,
      password
    );
    currentUser.value = user;
    return currentUser.value;
  }

  async function signUp(email: string, password: string) {
    await Auth.signUp({
      username: email,
      password: password,
      autoSignIn: {
        enabled: true
      }
    });
  }

  async function confirmAccount(email: string, otp: string) {
    await Auth.confirmSignUp(email, otp);
  }

  async function resendConfirmationCode(email: string) {
    await Auth.resendSignUp(email);
  }

  async function logout() {
    await Auth.signOut();
  }

  async function sendPasswordRecoveryEmail(email: string) {
    await Auth.forgotPassword(email);
  }

  async function setNewPassword(email: string, code: string, newPassword: string) {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  async function completeNewPassword(newPassword: string) {
    console.debug("current user: ", currentUser.value)
    await Auth.completeNewPassword(currentUser.value, newPassword);
  }

  async function deleteAccount() {
    console.log("deleting account")
    await Auth.deleteUser();
  }

  async function getCurrentUser() {
    console.debug('getting current user');
    try {
      const user = await Auth.currentAuthenticatedUser();
      currentUser.value = user;
    } catch(err: any) {
      // Return null if the user isn't signed in; otherwise, re-throw the error
      if(err === "The user is not authenticated") {
        currentUser.value = undefined;
      } else {
        throw err;
      }
    }

    return currentUser.value;
  }

  async function idToken(): Promise<string> {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  }

  function isFederatedUser() {
    return !!currentUser.value?.attributes.identities;
  }

  async function signInWithGoogle() {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    await Auth.changePassword(currentUser.value, oldPassword, newPassword);
  }

  async function getPreferredMFA() {
    return await Auth.currentAuthenticatedUser().then(user => user.preferredMFA)
  }

  async function setPreferredMFA(mfa: "TOTP" | "SMS" | "NOMFA" | "SMS_MFA" | "SOFTWARE_TOKEN_MFA") {
    const preferredMFA = await Auth.setPreferredMFA(currentUser.value, mfa)
    console.debug('set preferred MFA: ', preferredMFA);
    return preferredMFA
  }

  async function setMfaMethod(method: string) {
    mfaMethod.value = method;
  }

  async function getUserAttributes() {
    return await currentUser.value?.attributes
  }

  async function verifyTotpToken(code: string) {
    await Auth.verifyTotpToken(currentUser.value, code)
    const setMfaResult =  await setPreferredMFA('TOTP')
    await Auth.updateUserAttributes(currentUser.value, {
      'custom:totpMFA': mfaMethod.value
    })
    console.debug('verified totp token and set preferred MFA to TOTP: ', setMfaResult);
  }

  async function confirmSignInTotp(code: string) {
    console.log('attempting to sign in with totp: ', currentUser.value)
    await Auth.confirmSignIn(currentUser.value, code, "SOFTWARE_TOKEN_MFA");
    console.log('signing in with totp')
  }

  async function setAuthenticatorAppMFA() {
    return await Auth.setupTOTP(currentUser.value)
  }

  async function setOneTimePassword() {
    const thisUser = await Auth.currentAuthenticatedUser()
    return thisUser
  }

  return {
    currentUser,
    signIn,
    signUp,
    confirmAccount,
    resendConfirmationCode,
    logout, 
    deleteAccount,
    sendPasswordRecoveryEmail,
    setNewPassword,
    getPreferredMFA,
    setPreferredMFA,
    setAuthenticatorAppMFA,
    setOneTimePassword,
    isFederatedUser,
    signInWithGoogle,
    changePassword,
    verifyTotpToken,
    getCurrentUser,
    confirmSignInTotp,
    setMfaMethod,
    mfaMethod,
    getUserAttributes,
    completeNewPassword,
    idToken
  }
})