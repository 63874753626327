import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../views/DashboardLayoutView.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          redirect: '/dashboard/servers'
        },
        {
          path: '/marketplace',
          name: 'marketplace',
          component: () => import('../views/servers/CreateServerView.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'servers',
          name: 'list-servers',
          component: () => import('../views/servers/ListServersView.vue'),
        },
        {
          path: 'servers/:serverId',
          props: true,
          component: () => import('../views/servers/ShowServerView.vue'),
          children: [
            {
              path: '',
              name: 'server-dashboard',
              props: true,
              component: () => import('../views/servers/ServerDashboardView.vue')
            },
            {
              path: 'settings',
              name: 'server-settings',
              props: true,
              component: () => import('../views/servers/ServerSettingsView.vue')
            },
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('../views/UserSettingsView.vue'),
        },
      ]
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { requiresAuth: false },
      component: () => import('../views/auth/SignUpView.vue')
    },
    {
      path: '/login',
      name: 'login',
      alias: ['/'],
      meta: { requiresAuth: false },
      component: () => import('../views/auth/LoginView.vue')
    },
    {
      path: '/confirm-signup',
      name: 'confirm-signup',
      meta: { requiresAuth: false },
      component: () => import('../views/auth/email/ConfirmSignUpView.vue')
    },
    {
      path: '/two-factor-authentication',
      name: 'two-factor-authentication',
      meta: { requiresAuth: true },
      component: () => import('../views/auth/Select2FAMethod.vue'),
    },
    {
      path: '/setup-totp',
      name: 'setup-totp',
      meta: { requiresAuth: true },
      component: () => import('../views/auth/totp/SetupTotpView.vue'),
    },
    {
      path: '/login-totp',
      name: 'login-totp',
      component: () => import('../views/auth/totp/LoginTotpView.vue'),
      // this route should not have meta.requiresAuth set to anything
      // otherwise, the route guard will kick in and overwrite the "almost signed in" current user
      // with null, because technically, there's no current authenticated user
    },
    {
      path: '/setup-touchid',
      name: 'setup-touchid',
      meta: { requiresAuth: true },
      component: () => import('../views/auth/touchId/SetupTouchIdView.vue'),
    },
    {
      path: '/login-touchid',
      name: 'login-touchid',
      component: () => import('../views/auth/touchId/LoginTouchIdView.vue'),
    },
    {
      path: '/setup-yubikey',
      name: 'setup-yubikey',
      meta: { requiresAuth: true },
      component: () => import('../views/auth/yubikey/SetupYubikeyView.vue'),
    },
    {
      path: '/login-yubikey',
      name: 'login-yubikey',
      component: () => import('../views/auth/yubikey/LoginYubikeyView.vue'),
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: { requiresAuth: false },
      component: () => import('../views/auth/email/ResetPasswordView.vue')
    },
    // used in password recovery flow
    {
      path: '/set-new-password',
      name: 'set-new-password',
      meta: { requiresAuth: false },
      component: () => import('../views/auth/email/SetNewPasswordView.vue')
    },
    // used in user setup flow after admin created account
    {
      path: '/complete-new-password',
      name: 'complete-new-password',
      // meta: { requiresAuth: false },
      component: () => import('../views/auth/email/CompleteNewPasswordView.vue')
    }
  ]
})

router.beforeResolve(async (to, _from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth !== undefined)) {
    const auth = useAuthStore();
    const user = await auth.getCurrentUser();
    const destinationRequiresAuth = to.matched.some(record => record.meta.requiresAuth === true);

    if(!!user === destinationRequiresAuth) {
      next();
    } else if(user && !destinationRequiresAuth) {
      console.debug('User is logged in but route enforces no auth; redirect to dashboard')
      next({
        path: '/dashboard'
      })
    } else if(!user && destinationRequiresAuth) {
      console.debug('Route enforces auth but user is logged out. redirect to login')
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
});

export default router
