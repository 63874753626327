import { createInput, defaultConfig as formkitConfig } from '@formkit/vue'
import { createAutoAnimatePlugin } from '@formkit/addons'
import { generateClasses } from '@formkit/themes'
import OTPInput from './components/OTPInput.vue'

export const FormKitConfig = formkitConfig({
  inputs: {
    otp: createInput(OTPInput, {
      props: ['digits'],
      family: 'text'
    })
  },
  config: {
    classes: generateClasses({
      'family:text': {
        label: 'block text-sm font-medium leading-6 text-white',
        input: 'block mt-2 border-0 rounded-md py-1.5 text-white bg-gray-900 w-full shadow-sm ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 formkit-invalid:ring-red-500 disabled:text-white/20 disabled:ring-gray-800 disabled:cursor-not-allowed',
        help: 'mt-2 text-sm text-white/50',
        message: 'mt-2 text-sm text-red-600'
      },
      submit: {
        input: 'inline-flex items-center transition justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 ring-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 formkit-disabled:bg-indigo-300 disabled:cursor-not-allowed px-10 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
      },
      form: {
        form: 'flex flex-col space-y-6',
        message: 'mt-2 text-sm text-red-600'
      },
      checkbox: {
        wrapper: 'flex flex-row items-center',
        input: 'h-4 w-4 rounded border-gray-300 bg-gray-700 text-indigo-600 focus:ring-indigo-600',
        label: 'ml-3 block text-sm leading-6 text-white'
      },
      select: {
        label: 'block text-sm font-medium leading-6 text-white',
        input: 'mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 bg-gray-900 text-white ring-1 ring-inset ring-gray-700 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:text-white/20 disabled:ring-gray-800'
      },
      radio: {
        label: 'text-white',
        outer: 'text-white',
        wrapper: 'inline-flex items-center gap-2'
      }
    })
  },
  plugins: [
    createAutoAnimatePlugin(),
  ]
})