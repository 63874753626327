<script setup lang="ts">
import { onMounted } from 'vue';
import { ref, type Ref } from 'vue';

// Required for FormKit to work
const props = defineProps({
  context: Object,
})

// set the number of digits; default to 6
const digits = Number(props.context?.digits ?? 6);

// create a new array to hold each digit
const currentValue: Ref<string[]> = ref(props.context?.value?.split('').slice(0, digits) ?? new Array(digits).fill(' '));

function handleInput(digitIndex: number, event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const inputValue = inputElement.value.trim();

  // If a value is entered, update current value at the position of the digit
  if(inputValue.length === 1) {
    const currentDigits = currentValue.value;
    currentDigits[digitIndex] = inputElement.value.trim();
    currentValue.value = currentDigits;
  } else {
    currentValue.value[digitIndex] = ' ';
  }

  // Auto-focus the next/previous digit based on what was input
  if(inputValue && digitIndex < digits - 1) {
    // if we're not at the end of the digits, focus the next input
    const nextInput = inputElement.nextElementSibling as HTMLInputElement;
    nextInput.focus();
  } else if(!inputValue && digitIndex > 0) {
    const previousInput = inputElement.previousElementSibling as HTMLInputElement;
    previousInput.focus();
  }

  // Update the value of the input
  const currentValueString = currentValue.value.join('');
  if(currentValueString.trim().length === digits) {
    // if all of the digits are filled, update value and submit the form
    props.context?.node.input(currentValue.value.join(''))
  } else {
    // otherwise, set the value to nothing
    props.context?.node.input('')
  }
}

function handleFocus(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.select();
}

function handlePaste(event: ClipboardEvent) {
  const paste = event.clipboardData?.getData('text');

  // if the paste is a string, trim it and split it into an array
  // and cap the array's length at the number of digits
  if(typeof paste === 'string') {
    currentValue.value = paste.trim().split('').slice(0, digits);
  }
}

onMounted(() => {
  // when the component is mounted, focus the first input
  const firstInput = document.querySelector('input') as HTMLInputElement;
  firstInput.focus();
})

</script>

<template>
  <div class="flex flex-row items-center justify-between">
    <input 
      v-for="digit in digits"
      :key="digit"
      :value="currentValue[digit - 1]"
      @input="handleInput(digit - 1, $event)"
      @focus="handleFocus"
      @paste="handlePaste"
      autocomplete="one-time-code"
      class="block mt-2 w-10 h-12 border-0 rounded-md text-xl text-center bg-gray-900 font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 formkit-invalid:ring-red-500" 
    />
  </div>
</template>