import './assets/main.css'

import { createApp, provide, h } from 'vue'
import { createPinia } from 'pinia'
import { plugin as formkitPlugin } from '@formkit/vue'
import { FormKitConfig } from './formkit.config'
import { DefaultApolloClient } from '@vue/apollo-composable'

import { createAuthLink, type AuthOptions } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import App from './App.vue'
import router from './router'

import { Amplify, Auth } from 'aws-amplify'
import { amplifyConfig } from './amplify.config'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
Amplify.configure(amplifyConfig)
console.debug('Amplify config: ', amplifyConfig)

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_API_URL
})

const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => {
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  }
}

const link = ApolloLink.from([
  createAuthLink({
    url: import.meta.env.VITE_GRAPHQL_API_URL,
    region: import.meta.env.VITE_AWS_REGION,
    auth
  }),
  createSubscriptionHandshakeLink(
    {
      url: import.meta.env.VITE_GRAPHQL_API_URL,
      region: import.meta.env.VITE_AWS_REGION,
      auth
    },
    httpLink
  )
])

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getServer: {
            read(_, { args, toReference }) {
              const ref = toReference({
                __typename: 'Server',
                id: args?.id
              })
              return ref
            }
          }
        }
      }
    }
  })
})

console.debug('Apollo client: ', apolloClient)

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App)
})

app.use(createPinia())
app.use(router)
app.use(formkitPlugin, FormKitConfig)

app.mount('#app')
